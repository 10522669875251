
import {
  PedidoPaService,
  Pedido,
  pedidoStatus,
} from "@/services/PedidoService";
import { DataTableControl } from "@/services/Services";
import { defineComponent, reactive, ref } from "vue";
import Status from "../../pedidos/Status.vue";
import { useShowUser } from "@/components/UserView.vue";
import { useCancelarPa } from "@/composables/pedido";

interface PedidoDataTableControl extends DataTableControl {
  status: string;
}

export default defineComponent({
  components: { Status },
  data() {
    return {
      cadastroVisible: false,
      deleteItemDialog: false,
      item: {},
      selectedItens: null,
      submitted: false,
    };
  },
  setup() {
    const data = ref<Pedido[]>([]);
    const loading = ref(false);
    const dataParams = reactive<PedidoDataTableControl>({
      rows: 10,
      page: 0,
      sortField: "e.id",
      sortOrder: -1,
      totalRecords: 0,
      filter: "",
      status: null!,
    });

    async function find() {
      loading.value = true;
      try {
        const result = await new PedidoPaService().find({
          q: dataParams.filter,
          status: dataParams.status,
          first: dataParams.rows,
          page: dataParams.page + 1,
          sortField: dataParams.sortField,
          sortOrder: dataParams.sortOrder > 0 ? "ASC" : "DESC",
        });
        console.log(result);
        data.value = result.data;
        dataParams.totalRecords = result.paginatorInfo.total;
      } finally {
        loading.value = false;
      }
    }

    const userView = useShowUser();
    const { canCancelar, cancelar: doCancelar } = useCancelarPa();

    return {
      pedidoStatus,
      canCancelar,
      async cancelar(p: Pedido) {
        if (await doCancelar(p)) {
          find();
        }
      },
      userView,
      loading,
      dataParams,
      find,
      data,
    };
  },
  mounted() {
    this.find();
  },
  methods: {
    onPage(evt: any) {
      this.dataParams.rows = evt.rows;
      this.dataParams.page = evt.page;
      this.find();
    },
    onSort(evt: any) {
      this.dataParams.sortField = evt.sortField;
      this.dataParams.sortOrder = evt.sortOrder;
      this.find();
    },
    detalhes(pedido: Pedido) {
      this.$router.push({
        name: "pedido-detalhes",
        query: {
          pa: 1,
        },
        params: {
          id: pedido.id,
        },
      });
    },
  },
});
